import type { Workspace } from "~/server/routes/admin/v1/workspace"

export const workspaceUrl = "/admin/v1/workspace/"
export async function useWorkspaceSettings() {
  const result = await useCachedFetch<Workspace>(workspaceUrl, {
    lazy: false,
    dedupe: "defer",
  })

  function setWorkspace(newWorkspace: Workspace) {
    result.data.value = newWorkspace
  }

  return { workspaceUrl, ...result, setWorkspace }
}
